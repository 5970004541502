<template>
    <div v-if="shop">
        <!-- NavBar -->
        <n-navbar />
        <!-- Main -->
        <main>
            <!-- ShopInfo -->
            <figure class="text-left my-0 overflow-y-hidden">
                <!-- ShopImage -->
                <img v-if="shop.image" class="mb-3 overflow-y-hidden" :src="shop.image" />
                <div v-else class="bg-image-default flex-element center mb-3" style="height: 160px;">
                    <img class="mx-auto" src="@/assets/icons/ic-image-default.svg" width="80" height="80" />
                </div>
                <!-- ShopName -->
                <div class="name font-weight-700 mb-1 px-3 flex-element overflow-y-hidden">
                    <div>{{ shop.merchantName }} {{ shop.name }}</div>
                    <div class="icon-container">
                        <img src="@/assets/icons/ic-info.svg" @click="$router.push(`/${shop.id}/info`)" />
                    </div>
                </div>
                <!-- ShopOpeningHours -->
                <div class="font-15 line-height-15 mb-2 px-3 overflow-y-hidden">
                    <span :class="shopOpeningHours != '休息日' ? 'text-dark-warm-grey' : 'text-red'">{{ shopOpeningHours }}</span>
                    <span v-if="shop.isClosed" class="font-weight-500 text-red">（暫時關閉接單）</span>
                    <span v-else-if="shop.isBusy" class="font-weight-500 text-red">（現時店家繁忙）</span>
                </div>
                <!-- CategoryTabs -->
                <scrollactive id="category-tabs" class="category-tabs" v-on:itemchanged="handleCategoryChanged" active-class="active" :offset="scrollOffset" :duration="400" bezier-easing-value=".17,.67,.83,.67" scrollContainerSelector="document" :modifyUrl="false">
                    <a v-for="category in shop.categories" :key="category.id" :id="category.id + 'tab'" :href="'#' + category.name" class="scrollactive-item">{{ category.name }}</a>
                </scrollactive>
                <!-- FixedCategoryTabs -->
                <scrollactive v-on:itemchanged="handleCategoryChanged" active-class="active" :offset="scrollOffset" :duration="400" bezier-easing-value=".17,.67,.83,.67" scrollContainerSelector="document" :modifyUrl="false" :class="{ 'show': showFixedTabs }" id="category-tabs-fixed" class="category-tabs fixed">
                    <a v-for="category in shop.categories" :key="category.id" :id="category.id + 'tab'" :href="'#' + category.name" class="scrollactive-item">{{ category.name }}</a>
                </scrollactive>
            </figure>
            <!-- Reservation -->
            <div v-if="!shop.isClosed" class="reservation-container">
                <!-- CartMethod -->
                <div class="text-center">
                    <div class="ship-methods mb-10">
                        <button v-if="shop.orderMethods.includes('PICKUP')" :class="{ 'bg-white text-black' : cart.method ==='PICKUP' }" @click="cart.products.length > 0 ? null : cart.method = 'PICKUP'">自取</button>
                        <button v-if="shop.orderMethods.includes('DELIVERY')" :class="{ 'bg-white text-black' : cart.method ==='DELIVERY' }" @click="cart.products.length > 0 ? null : cart.method = 'DELIVERY'">外送</button>
                    </div>
                </div>
                <!-- CartReservation -->
                <button class="content" @click.prevent="$router.push(`/${shop.id}/time`)">
                    <span v-if="!cart.reservationAt && cart.type != 'ASAP'">請選擇取餐時間</span>
                    <!-- PICKUP -->
                    <span class="text-left" v-else-if="cart.method == 'PICKUP'">
                        <div class="font-17 font-weight-400 text-dark-warm-grey mb-1">{{ cart.type == 'ASAP' ? '馬上取餐' : '指定時間取餐' }}</div>
                        <div class="font-17 font-weight-500 text-black">{{ cart.type == 'ASAP' ? '越快越好（預估等待 ' + (shop.isBusy ? shop.busyTime : shop.readyTime) + '分鐘）' : cartRservationAt }}</div>
                    </span>
                    <!-- DELIVERY -->
                    <span class="text-left" v-else-if="cart.method == 'DELIVERY'">
                        <span class="text-deep-blue font-17 font-weight-500" v-if="!cart.customer.address">設定外送地點</span>
                        <div v-else>
                            <div class="font-17 font-weight-400 text-dark-warm-grey mb-1">{{ cart.customer.address }}</div>
                            <div class="font-17 font-weight-500 text-black">{{ cart.type == 'ASAP' ? '越快越好（預估等待 ' + (shop.isBusy ? shop.busyTime : shop.readyTime) + '分鐘）' : cartRservationAt }}</div>
                        </div>
                    </span>
                    <img src="@/assets/icons/ic-morder-arrow-right.svg" width="24" height="24" />
                </button>
            </div>
            <!-- CategoryList -->
            <section class="pb-3">
                <ul v-for="(category, categoryIndex) in shop.categories" :key="categoryIndex" :id="category.name" :class="{ 'pb-64': categoryIndex == shop.categories.length - 1, 'pt-22': categoryIndex == 0 }">
                    <!-- CategoryName -->
                    <div :class="{ 'pt-4': categoryIndex !== 0 }" class="font-21 text-greyish font-weight-500 pb-2">{{ category.name }}</div>
                    <!-- ProductList -->
                    <li v-for="(product, productIndex) in category.products" :key="productIndex" class="product-item" :class="{ 'disabled': product.soldOutAtShopIds.includes(shop.id) }" :disabled="product.soldOutAtShopIds.includes(shop.id)" @click="$router.push(`/${shop.id}/product/${product.id}`)">
                        <!-- ProductImage -->
                        <div class="img-container">
                            <img v-if="product.image" :src="product.image" />
                            <div v-else class="bg-white-fa flex-element center border-radius-4">
                                <img class="img-default mx-auto" src="@/assets/icons/ic-image-default.svg" />
                            </div>
                            <span v-if="product.soldOutAtShopIds.includes(shop.id)" class="disabled-text">已售完</span>
                        </div>
                        <!-- ProductInfo -->
                        <div class="content">
                            <span class="name">{{ product.name }}</span>
                            <span class="description" v-if="product.description">{{ product.description }}</span>
                            <span class="price">${{ product.price[cart.method] }}</span>
                        </div>
                    </li>
                </ul>
            </section>
        </main>
        <!-- CartBar -->
        <div v-if="cart.products.length > 0" class="cart-bar" @click="$router.push(`/${shop.id}/bag`)">
            <div class="view-bag">
                <span class="count">{{ cartProductsCount }}</span>
                <span class="topic">檢視購物車</span>
            </div>
            <span class="price">${{ cart.grandTotal }}</span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import _ from 'lodash'
import moment from 'moment'

export default {
    data() {
        return {
            top: 0,
            scrollPos: 0,
            scrollDirection: 'down',
            scrollOffset: 112,
            showFixedTabs: false,
        }
    },

    mounted() {
        document.addEventListener('scroll', this.handleScroll, { passive: true })
    },

    beforeDestroy () {
        document.removeEventListener('scroll', this.handleScroll, { passive: true })
    },

    computed: {
        ...mapState({
            shop: state => state.shop,
            cart: state => state.cart,
        }),

        // 計算今日營業時間句子
        shopOpeningHours () {
            let today = moment().isoWeekday() - 1
            let todayOpeningHours = this.shop.openingHours ? this.shop.openingHours[today] : '休息日'
            if (!todayOpeningHours && todayOpeningHours.length < 1) return '休息日'
            let str = '今日點餐時間 '
            _.forEach(todayOpeningHours, (time, index) => {
                str += index === todayOpeningHours.length -1 ? time.start + ' 至 ' + time.end + '。' : time.start + ' 至 ' + time.end + '、'
            })
            return str
        },

        // 計算送餐時間句子
        cartRservationAt () {
            return moment.unix(this.cart.reservationAt).format('MM/DD HH:mm')
        },

        // 計算購物車商品數量
        cartProductsCount() {
            return _.sumBy(this.cart.products, 'count')
        }
    },

    methods: {
        // 處理滾動時的Style
        handleScroll() {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0
            if (Math.abs(scrollTop - this.scrollPos) > 10) {
                this.scrollDirection = (scrollTop < this.scrollPos) ? 'UP' : 'DOWN'
                this.scrollPos = scrollTop
                this.showFixedTabs = (this.scrollPos > 250)
                if (this.cart.method) {
                    this.scrollOffset = (this.scrollPos > 400 && this.scrollDirection === 'UP' && this.cart.method !== 'forHere') ? 185 : 110
                } else {
                    this.scrollOffset = 110
                }
            }
        },

        // 處理選擇分類時的Style
        handleCategoryChanged(event, currentItem) {
            if (!currentItem) return
            document.getElementById('category-tabs').scrollTo({ left: currentItem.offsetLeft - 16 })
            document.getElementById('category-tabs-fixed').scrollTo({ left: currentItem.offsetLeft - 16 })
        },
    }
}
</script>